var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-page"},[_c('list-layout',{ref:"listLayout",attrs:{"filter-form":_vm.filterForm,"thead":_vm.thead,"tab-arr":_vm.tabArr,"on-fetch":_vm.getList},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('list-filter',{attrs:{"filterForm":_vm.filterForm,"uploadFilter":_vm.refreshList}})]},proxy:true},{key:"tabletop",fn:function(){return undefined},proxy:true},{key:"item_name",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goods-name"},[_c('div',{staticClass:"left"},[(row.orderGoods[0])?_c('list-image',{attrs:{"fit":"cover","width":80,"border-radius":"5%","src":row.orderGoods[0].image}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"goods-attr"},[_vm._v(_vm._s(row.orderGoods[0].name))]),_c('div',{staticClass:"goods-specs"},[_vm._v(" "+_vm._s(Object.values(row.orderGoods[0].specs.attrs).join(";"))+" ")]),(row.orderGoods.length > 1)?_c('div',{staticClass:"more"},[_c('el-popover',{attrs:{"placement":"right-end","trigger":"hover"}},[_c('el-table',{attrs:{"data":row.orderGoods}},[_c('el-table-column',{attrs:{"min-width":"250","prop":"specs","label":"商品名称/属性"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goods-name"},[_c('div',{staticClass:"left"},[(row)?_c('list-image',{attrs:{"fit":"cover","width":80,"border-radius":"5%","src":row.image}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"goods-attr"},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('div',{staticClass:"goods-specs"},[_vm._v(" "+_vm._s(Object.values(row.specs.attrs).join(";"))+" ")])])])]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"150","prop":"price","label":"单价数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"price_count"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.price))]),_c('div',{staticClass:"count"},[_vm._v("x "+_vm._s(row.amount))])])]}}],null,true)})],1),_c('el-link',{attrs:{"slot":"reference","type":"primary","underline":false},slot:"reference"},[_vm._v("显示更多")])],1)],1):_vm._e()])])]}},{key:"item_count",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"price_count"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.orderGoods[0].price))]),_c('div',{staticClass:"count"},[_vm._v("x "+_vm._s(row.orderGoods[0].amount))])])]}},{key:"item_order_price",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"order_price"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(row.order_price.toFixed(2)))]),_c('div',{staticClass:"text"},[_vm._v("实付款：￥"+_vm._s(row.order_price.toFixed(2)))])])]}},{key:"item_receive_text",fn:function(ref){
var row = ref.row;
return [_c('list-status-text',{attrs:{"type":row.receive_status == 0 ? 'warning' : 'success',"text":row.receive_text}})]}},{key:"item_apply_refund",fn:function(ref){
var row = ref.row;
return [_c('list-status-text',{attrs:{"type":row.apply_refund == 1
            ? 'warning'
            : row.apply_refund == 2
            ? 'success'
            : row.apply_refund == 3
            ? 'danger'
            : 'info',"text":row.apply_refund == 1 ? '待商家受理' : row.apply_refund_text}})]}},{key:"top",fn:function(){return undefined},proxy:true},{key:"operation",fn:function(scope){return [(scope.row.apply_refund == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.agreeRow(scope.row)}}},[_vm._v("同意")]):_vm._e(),(scope.row.apply_refund == 1)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.refuseRow(scope.row)}}},[_vm._v("拒绝")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.editRow(scope.row)}}},[_vm._v("详情")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }