<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
    >
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :uploadFilter="refreshList"
        ></list-filter>
      </template>
      <template #tabletop></template>
      <template slot="item_name" slot-scope="{ row }">
        <div class="goods-name">
          <div class="left">
            <!-- 默认显示第一个商品规格 -->
            <list-image
              v-if="row.orderGoods[0]"
              fit="cover"
              :width="80"
              border-radius="5%"
              :src="row.orderGoods[0].image"
            />
          </div>
          <div class="right">
            <div class="goods-attr">{{ row.orderGoods[0].name }}</div>
            <div class="goods-specs">
              {{ Object.values(row.orderGoods[0].specs.attrs).join(";") }}
            </div>
            <div class="more" v-if="row.orderGoods.length > 1">
              <el-popover placement="right-end" trigger="hover">
                <el-table :data="row.orderGoods">
                  <el-table-column
                    min-width="250"
                    prop="specs"
                    label="商品名称/属性"
                  >
                    <template slot-scope="{ row }">
                      <div class="goods-name">
                        <div class="left">
                          <!-- 默认显示第一个商品规格 -->
                          <list-image
                            v-if="row"
                            fit="cover"
                            :width="80"
                            border-radius="5%"
                            :src="row.image"
                          />
                        </div>
                        <div class="right">
                          <div class="goods-attr">
                            {{ row.name }}
                          </div>
                          <div class="goods-specs">
                            {{ Object.values(row.specs.attrs).join(";") }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="150"
                    prop="price"
                    label="单价数量"
                  >
                    <template slot-scope="{ row }">
                      <div class="price_count">
                        <div class="price">￥{{ row.price }}</div>
                        <div class="count">x {{ row.amount }}</div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-link type="primary" slot="reference" :underline="false"
                  >显示更多</el-link
                >
              </el-popover>
            </div>
          </div>
        </div>
      </template>
      <template slot="item_count" slot-scope="{ row }">
        <div class="price_count">
          <div class="price">￥{{ row.orderGoods[0].price }}</div>
          <div class="count">x {{ row.orderGoods[0].amount }}</div>
        </div>
      </template>

      <template slot="item_order_price" slot-scope="{ row }">
        <div class="order_price">
          <div class="price">￥{{ row.order_price.toFixed(2) }}</div>
          <div class="text">实付款：￥{{ row.order_price.toFixed(2) }}</div>
        </div>
      </template>

      <template slot="item_receive_text" slot-scope="{ row }">
        <list-status-text
          :type="row.receive_status == 0 ? 'warning' : 'success'"
          :text="row.receive_text"
        />
      </template>
      <template slot="item_apply_refund" slot-scope="{ row }">
        <list-status-text
          :type="
            row.apply_refund == 1
              ? 'warning'
              : row.apply_refund == 2
              ? 'success'
              : row.apply_refund == 3
              ? 'danger'
              : 'info'
          "
          :text="row.apply_refund == 1 ? '待商家受理' : row.apply_refund_text"
        />
      </template>
      <template #top> </template>
      <template v-slot:operation="scope">
        <el-button
          v-if="scope.row.apply_refund == 1"
          type="text"
          size="small"
          @click="agreeRow(scope.row)"
          >同意</el-button
        >

        <el-button
          v-if="scope.row.apply_refund == 1"
          type="text"
          size="small"
          @click="refuseRow(scope.row)"
          >拒绝</el-button
        >
        <el-button type="text" size="small" @click="editRow(scope.row)"
          >详情</el-button
        >
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import ListFilter from "../components/ShopRefund/ListFilter";
import ListImage from "@/base/components/List/ListImage";

import {
  getDataList,
  refundApplyUnCheck,
  refundApplyCheck,
} from "../api/refund-list";
export default {
  components: {
    ListFilter,
    ListLayout,
    ListStatusText,
    ListImage,
  },
  data() {
    return {
      tabArr: [
        { label: "全部", name: "all" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "通过", name: "checked" },
        { label: "不通过", name: "uncheck" },
      ],
      thead: [
        //   { type: "selection" },
        { label: "订单号", prop: "orderid", minWidth: 100 },
        { label: "商品名称/属性", prop: "name", minWidth: 300, type: "slot" },
        {
          label: "单价/数量",
          prop: "count",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "发货状态",
          prop: "receive_text",
          minWidth: 120,
          type: "slot",
        },
        {
          label: "退款金额",
          prop: "order_price",
          minWidth: 150,
          type: "slot",
        },

        {
          label: "售后状态",
          prop: "apply_refund",
          minWidth: 120,
          type: "slot",
        },

        {
          label: "创建时间",
          prop: "apply_refund_time",
          minWidth: 200,
          sortable: true,
        },
        { label: "操作", type: "operation", minWidth: 150 },
      ],
      // 筛选对象
      filterForm: {
        tab: "all",
        name: "", // 商品名
        user_name: "", // 用户名
        merchant: "", // 商户号
        order_by: "apply_refund_time", // 排序字段
        is_desc: 1, // 正序还是倒序
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page_size: 15, //每页多少条数据
      },
      currentItem: {},
      // 拒绝退款弹窗
      openRefuse: false,
      // 同意退款弹窗
      openAgree: false,
    };
  },
  methods: {
    getList(data) {
      return getDataList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    // 查看详情
    editRow(row) {
      this.currentItem = row;
      this.$router.push({
        name: "OrderRefundDetail",
        params: {
          id: row.orderid,
        },
      });
    },
    // 同意退款
    agreeRow(row) {
      this.$confirm("是否同意退款?", "提示", {
        confirmButtonText: "同意退款",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "退款中...";
            refundApplyCheck({ orderid: row.orderid }).then((res) => {
              instance.confirmButtonLoading = false;
              this.$message.success("已同意退款");
              done();
            });
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        },
      })
        .then(() => {
          this.refreshList();
        })
        .catch(() => {});
    },
    // 拒绝退款
    refuseRow(row) {
      this.$prompt("", "拒绝的原因", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "必须输入拒绝的原因才可以拒绝",
        beforeClose: (action, instance, done) => {
          console.log(action, instance);
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            if (instance.inputValue) {
              refundApplyUnCheck({
                orderid: row.orderid,
                refund_remarks: instance.inputValue,
              }).then((res) => {
                instance.confirmButtonLoading = false;
                this.$message.success("已拒绝退款");
                done();
              });
            } else {
              this.$message.error("请输入拒绝的原因");
              done();
            }
          } else {
            instance.confirmButtonLoading = false;
            done();
          }
        },
      })
        .then(({ value }) => {
          this.refreshList();
        })
        .catch(() => {});
    },

    // 计算订单商品总和运费
    totalFreight(goods) {
      let count = goods.reduce((prev, cur, index, arr) => {
        return prev + cur.postage_price;
      }, 0);
      return count.toFixed(2);
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-name {
  display: flex;
  height: 80px;
  .left {
    margin-right: 10px;
  }

  .right {
    .goods-attr {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .goods-specs {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .more {
      el-button {
      }
    }
  }
}
.price_count {
  height: 80px;
  .price {
  }

  .count {
  }
}
.user {
  .item {
    display: flex;
    .left {
      min-width: 60px;
      margin-right: 5px;
    }

    .right {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.order_price {
  .price {
    font-weight: 700;
  }

  .text {
    color: #b3b3b3;
    font-size: 12px;
  }
}
</style>
