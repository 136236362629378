<template>
  <LayoutFilter
    :onFilter="ok"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="exportApi?onExport:null"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="templateForm"
      label-width="80px"
      size="medium"
      class="filter-from"
    >
      <div class="filter-item">
        <el-form-item label="商户号：" prop="merchant">
          <el-input v-model="form.merchant" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="商品名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="买家名称：" prop="user_name">
          <el-input v-model="form.user_name" placeholder="请输入内容"></el-input>
        </el-form-item>

  
      
      </div>
 
      <div class="filter-item">
        <DatePeriodPicker
          label="创建时间："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { exportList } from "../../api/order-list";

export default {
  name: "ListFilter",
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    exportApi: Function
  },
  data() {
    return {
      rules: {},
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      // 订单状态选项
      statusOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "已支付",
        },
        {
          value: 1,
          label: "未支付",
        },
        {
          value: 2,
          label: "已取消",
        },
        {
          value: 3,
          label: "已退款",
        },
        {
          value: 4,
          label: "退款中",
        },
      ],
      // 商品类型选项
      dataTypeOptions: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 0,
          label: "微信小商店商品",
        },
        {
          value: 1,
          label: "商城商品",
        },
      ],
      // 分类
      categoryOption: [],
    };
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  created() {
  },
  methods: {
    onExport() {
      const postData = { ...this.form };
      if (this.exportApi) return this.exportApi(postData)
      return exportList(postData);
    },
    //重置表单
    resetForm() {
      this.$refs["templateForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 分类列表
    getCategoryList() {
      getCategory().then((res) => {
        this.categoryOption = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 0 5px;
  }
}
</style>
