/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


// 列表数据
export const getDataList = data => {
  return api({
    url: '/admin/shop/refundApply/index',
    method: 'post',
    data
  });
};



// 订单详情
export const getDetail = (data) => {
  return api({
    url: '/admin/shop/orders/detail',
    method: 'post',
    data
  });
};

// 审核通过
export const refundApplyCheck = (data) => {
  return api({
    url: '/admin/shop/refundApply/check',
    method: 'post',
    data
  });
};
// 审核不通过
export const refundApplyUnCheck = (data) => {
  return api({
    url: '/admin/shop/refundApply/uncheck',
    method: 'post',
    data
  });
};

