/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 09:59:47
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-26 17:26:47
 */

import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";


// 列表数据
export const getDataList = data => {
  return api({
    url: '/admin/shop/orders/index',
    method: 'post',
    data
  });
};


// 商品还原
export const putBack = data => {
  return api({
    url: '/admin/shop/orders/putBack',
    method: 'post',
    data
  });
};
// 列表导出
export const exportList = (data) => {
  return fetchFile({
    url: "/admin/shop/orders/exportIndex",
    method: "post",
    data,
    download: true,
  });
};
// 保存发货信息
export const saveExpressInfo = (data) => {
  return api({
    url: '/admin/shop/orders/saveExpressInfo',
    method: 'post',
    data
  });
};
// 订单详情
export const getDetail = (data) => {
  return api({
    url: '/admin/shop/orders/detail',
    method: 'post',
    data
  });
};

